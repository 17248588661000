<template>
  <sync-popup v-if="inventionSync.inProgress" :invention-sync="inventionSync" :application-number="applicationNumber" class="notification" />
  <section v-else class="invention-details">
    <section v-if="isGetInventionRequestPending" calss="loading">
      <hub-icon name="loading" spin size="lg"></hub-icon>
    </section>
    <template v-else>
      <div>
        <header>
          <div v-if="!isUpdating">
            <div class="invention-title">
              <hub-reference-list
                v-if="invention"
                v-model:invention="thisInvention"
                :show-navigation="false"
                :show-prefixes="true"
                :external-navigation-enabled="true"
                :edit-mode="editMode"
                @references:updated="onReferenceUpdated"
              />
              <div v-if="!previewMode">
                <div v-if="!editMode">
                  <hub-button variant="icon" class="refresh" title="Edit invention" @click="edit"
                    ><hub-icon name="pencil" size="md"></hub-icon
                  ></hub-button>
                  <hub-button v-if="matterNumber?.length || fipId?.length" variant="icon" class="refresh" title="Synchronize invention" @click="sync"
                    ><hub-icon name="autorenew" size="md" :spin="hover" @mouseover="hover = true" @mouseleave="hover = false"></hub-icon
                  ></hub-button>
                </div>
                <div v-else>
                  <hub-button variant="icon" class="refresh" title="Submit" @click="submit"
                    ><hub-icon name="check" size="md" :disabled="isUpdating"></hub-icon
                  ></hub-button>
                  <hub-button variant="icon" class="refresh" title="Revert" @click="revert"
                    ><hub-icon name="close" size="md" :disabled="isUpdating"></hub-icon
                  ></hub-button>
                </div>
              </div>
            </div>
            <label v-if="!editMode">
              {{ thisInvention.title }}
            </label>
            <hub-text-field v-else v-model="thisInvention.title" class="invention-title-edit" :spellcheck="true" />
          </div>
          <div v-else class="updating-progress">
            <hub-icon name="loading" spin size="sm" style="padding-right: 0.2rem"></hub-icon> Updating invention...
          </div>
        </header>
        <nav-bar />
      </div>
      <router-view class="content" :references="invention?.references" />
    </template>
  </section>
</template>

<script>
import { mapState } from 'vuex';

import httpClient from '@/utils/httpClient';
import logger from '@/utils/logger';

import Button from '@/components/common/Button';
import ReferenceList from '@/components/search/ReferenceList';
import Icon from '@/components/common/Icon';

import NavBar from '@/components/inventions/pageParts/NavBar';
import SyncPopup from '@/components/inventions/pageParts/SyncPopup';
import TextField from '@/components/common/TextField';

export default {
  components: {
    'hub-icon': Icon,
    'hub-button': Button,
    'hub-reference-list': ReferenceList,
    'hub-text-field': TextField,
    NavBar,
    SyncPopup
  },
  props: {
    viewMode: {
      type: String,
      default: 'detailed'
    }
  },
  data() {
    return {
      id: '',
      inventionSync: {
        inProgress: false,
        applicationScheduled: false,
        fipSynced: false
      },
      hover: false,
      editMode: false,
      thisInvention: {
        title: '',
        reference: []
      },
      beforeEdit: {
        title: '',
        reference: []
      }
    };
  },
  computed: {
    ...mapState({
      my: s => s.inventions.my,
      isGetMyCollectionRequestPending: s => s.inventions.isGetMyCollectionRequestPending,
      invention: s => s.inventions.item,
      isGetInventionRequestPending: s => s.inventions.isGetRequestPending,
      isUpdating: s => s.inventions.isUpdating
    }),
    previewMode() {
      return this.$route.query.previewMode === 'true';
    },
    matterNumber() {
      return (this.invention && this.invention.references.find(r => r.startsWith('HARRITY:'))?.split(':')[1]) || '';
    },
    fipId() {
      return (this.invention && this.invention.references.find(r => r.startsWith('FIP:'))?.split(':')[1]) || '';
    },
    applicationNumber() {
      return ((this.invention && this.invention.references.find(r => r.startsWith('US:'))?.split(':')[1]) || '').replace('/', '').replace(',', '');
    }
  },
  watch: {
    async '$route.params.id'(prev, next) {
      if (!this.$route.params.id) {
        return;
      }
      await this.$store.dispatch('inventions/getById', this.$route.params.id);
    },
    invention(prev, next) {
      this.editMode = false;
      this.thisInvention = {
        title: this.invention.title,
        references: this.invention.references
      };
    }
  },
  async created() {
    await this.$store.dispatch('inventions/getById', this.$route.params.id);
  },
  methods: {
    async sync() {
      this.hover = false;
      if (!this.matterNumber && !this.fipId) {
        return;
      }

      try {
        this.inventionSync.inProgress = true;

        if (this.applicationNumber) {
          await httpClient.post(`/api/uspto/downloads/`, { collection: [this.applicationNumber] });
          this.inventionSync.applicationScheduled = true;
        }

        if (this.fipId) {
          await httpClient.get(`/api/fip-connector/matters/byFipId/FIPID:${this.fipId}`, null, {
            'x-no-cache': true
          });
        } else {
          await httpClient.get(`/api/fip-connector/matters/${this.matterNumber}`, null, {
            'x-no-cache': true
          });
        }

        this.inventionSync.fipSynced = true;

        await new Promise(resolve => setTimeout(resolve, 1000));

        await this.$store.dispatch('inventions/getById', this.$route.params.id);
      } catch (error) {
        logger.error(error);
      } finally {
        this.inventionSync = {
          inProgress: false,
          applicationScheduled: false,
          fipSynced: false
        };
      }
    },
    edit() {
      this.beforeEdit = {
        title: this.invention.title,
        references: this.invention.references
      };
      this.editMode = true;
    },
    async submit() {
      this.editMode = false;
      const updatedInvention = {
        id: this.invention.id,
        title: this.thisInvention.title,
        references: this.thisInvention.references
      };

      await this.$store.dispatch('inventions/updateInvention', updatedInvention);
      this.$store.dispatch('inventions/getById', updatedInvention.id);
      this.$store.dispatch('reports/myInventions/getCollection');
    },
    revert() {
      this.editMode = false;
      this.thisInvention = {
        title: this.beforeEdit.title,
        references: this.beforeEdit.references
      };
    },
    onReferenceUpdated(event) {
      this.thisInvention.references = event;
    }
  }
};
</script>

<style lang="scss" scoped>
.loading {
  display: grid;
  justify-content: flex-start;
  align-content: center;
  margin-left: 0.75rem;
}

.invention-title {
  display: grid;
  grid-template-columns: 1fr auto;
  justify-content: space-between;

  .refresh {
    &:hover {
      background: var(--theme-highlight);
    }
    margin-left: 0.3rem;
    border-radius: 0%;
  }
}

.report-loading {
  justify-self: center;
  align-self: center;
}

.invention-details {
  display: grid;
  grid-template-rows: max-content 1fr;
  overflow: hidden;
  height: 100%;
}

.updating-progress {
  font-style: italic;
  font-size: 0.7rem;
  opacity: 0.8;
  padding-left: 0.5rem;
}

header {
  padding: 0.5rem 0.5rem 0.75rem;
  font-size: 1rem;
  font-weight: 700;
  grid-row: 1;

  label {
    color: var(--theme-on-surface-accent);
  }
}

.content {
  display: grid;
  align-content: stretch;
  justify-content: stretch;
  width: 100%;
  padding-left: 0.5rem;
}
</style>
